import React, { useState, useEffect } from "react";
import "./Health.css";
import Header from "../Header";
import Button1 from "../Button1";
import Footer from "../Footer.jsx";
import InfoBox from "../infoBox/InfoBox";
import Select1 from "../Select1";
import Report from "../report";
import APReport from "../apHealth";
import RadioButton from "../RadioButton";
import TwilioReport from "../twilioSIM";
import MediaReport from "../mediaServer.js";

function Health({ setroute }) {
  // eslint-disable-next-line no-unused-vars
  const [header, setHeader] = useState("Health Report Generation");
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loggedIn, setloggedIn] = useState(false);
  const [select, setSelect] = useState(true);
  const [akey, setAkey] = useState(null);
  const [uskey, setUskey] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState("TIM");
  const [selectedReport, setSelectedReport] = useState("Consolidated");
  // const [selectedReport, setSelectedReport] = useState("Consolidated");
  const [selectedNetworkReport, setSelectedNetworkReport] = useState("AP");
  const [selectedValue, setSelectedValue] = useState("Media");
  // const [selectedNetwork,setSelectedNetworkType] = useState('AP');
  const [selectedReportType, setSelectedReportType] = useState("Consolidated");
  const [selectedMediaReportType, setSelectedMediaReportType] = useState("Consolidated");
  // eslint-disable-next-line no-unused-vars
  const [selectedMediaReport, setSelectedMediaReport] = useState("Consolidated");
  // eslint-disable-next-line no-unused-vars
  const [isNetwork, setIsNetwork] = useState(false);

  var region;
  var key;
  var options;

  var NetworkReportOption;
  let networkReportType = [
    { value: "Consolidated", label: "Consolidated" },
    { value: "Detailed", label: "Detailed" },
  ];

  let reportType = [
    { value: "Consolidated", label: "Consolidated" },
    { value: "Detailed", label: "Detailed" },
  ];

  let mediaReportType = [
    { value: "Consolidated", label: "Consolidated" },
    // { value: "Detailed", label: "Detailed" },
  ];
  var urlParams = new URLSearchParams(window.location.search);
  const xAuthKey = localStorage.getItem("spaceio-key");
  if (urlParams.get("region") != null && xAuthKey != null) {
    region = urlParams.get("region");
    if (region === "US") {
      options = [
        { value: "TIM", label: "TIM" },
        { value: "FLO", label: "FLO"},
        { value: "TIM+", label: "TIM+"},
        { value: "BAT-TIM", label: "TIM+"},
        { value: "BAT-FLO", label: "BAT-FLO" },
        { value: "PAM", label: "PAM" },
        { value: "PAL", label: "PAL" },
      ];
      NetworkReportOption = [
        { value: "AP", label: "AP", isDisabled: true },
        { value: "Twilio SIM", label: "Twilio SIM", isDisabled: true },
      ];
    } else {
      options = [
        { value: "TIM", label: "TIM" },
        { value: "FLO", label: "FLO" },
        { value: "TIM+", label: "TIM+" },
        { value: "BAT-TIM", label: "BAT-TIM" },
        { value: "BAT-FLO", label: "BAT-FLO" },
        { value: "PAM", label: "PAM" },
        { value: "PAL", label: "PAL" },
      ];
      NetworkReportOption = [
        { value: "AP", label: "AP" },
        { value: "Twilio SIM", label: "Twilio SIM" },
      ];
    }
  }

 
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    
    if (selectedValue === "Network") {
      setSelectedNetworkReport("AP");
      // setSelectedFloor('TIM');
      setSelectedReport("Consolidated");
      setSelectedReportType("Consolidated");
    } else if (selectedValue === "Device") {
      // setSelectedNetworkReport('AP');
      setSelectedFloor("TIM");
      setSelectedReport("Consolidated");
      setSelectedReportType("Consolidated");
    }
  };

  useEffect(() => {
    const xAuthKey = localStorage.getItem("spaceio-key");

    var urlParams = new URLSearchParams(window.location.search);
    localStorage.setItem("region", urlParams.get("region"));
    // console.log(urlParams.get("region"))
    if (urlParams.get("region") != null && xAuthKey != null) {
      region = urlParams.get("region");
      key = xAuthKey;
      // console.log("loggedIn")
      test();
    }
    // console.log(region, key)
  });

  // var urlParams = new URLSearchParams(window.location.search);

  function test() {
    let tokenKey = key;
    let tokenKeyUS = key;

    if (region === "UK") {
      setAkey(tokenKey);
    } else {
      setUskey(tokenKeyUS);
    }
    if (tokenKey || tokenKeyUS) {
      // console.log(tokenKey, tokenKeyUS);
      setloggedIn(tokenKey + "," + tokenKeyUS);
    } else {
      setErrorMessage(
        `for ${
          region === "UK" ? "UK" : "US"
        } ,Incorrect Email or Password , Please verify it.`
      );
    }
  }

  return (
    <div className="col-lg-12 p-0">
      <Header header={header} />

      <div className="container-fluid body">
        <form
          className="col-xl-4 col-lg-5 col-sm-6 col-10"
          style={{ margin: "2%", flexGrow: "1", outline: "1px " }}
        >
          {select ? (
            <div>
              {/* InfoBox */}
              <InfoBox
                text={"Select the type of report to be generated"}
              />{" "}
              <br />
              {/* Radio Buttons */}
              {region === "UK" ? (
                <div
                  className="radio-container"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <RadioButton
                    value="Network"
                    name="Network"
                    selectedValue={selectedValue}
                    onChange={handleRadioChange}
                  />
                  <RadioButton
                    value="Device"
                    name="Device"
                    selectedValue={selectedValue}
                    onChange={handleRadioChange}
                  />
                  <RadioButton
                    value="Media"
                    name="Media Server"
                    selectedValue={selectedValue}
                    onChange={handleRadioChange}
                  />
                </div>
              ) : null}
              {selectedValue === "Network" ? (
                <div>
                  <label className="label-class mt-4">
                    Select a Network Component
                  </label>
                  <Select1
                    value={
                      selectedNetworkReport === "null"
                        ? ""
                        : {
                            label: selectedNetworkReport,
                            value: selectedNetworkReport,
                          }
                    }
                    placeholder={"Select"}
                    options={NetworkReportOption}
                    onChange={setSelectedNetworkReport}
                    isOptionDisabled={(NetworkReportOption) =>
                      NetworkReportOption.isDisabled
                    }
                  />
                </div>
              ) : selectedValue === "Media" ? (
                <div></div>
              ) : (
                <div>
                  <label className="label-class mt-4">
                    Select a Device Type
                  </label>
                  <Select1
                    value={
                      selectedFloor === "null"
                        ? ""
                        : { label: selectedFloor, value: selectedFloor }
                    }
                    placeholder={"Select"}
                    options={options}
                    onChange={setSelectedFloor}
                    isOptionDisabled={(option) => option.isDisabled}
                  />
                </div>
              )}
              {/* <label className="label-class mt-4">Select Report Type</label> */}
              {/* <Select1
                defaultValue={selectedValue=='Network'? selectedReportType : selectedReport === "null" ? "" : { label: selectedValue=='Network'? selectedReportType : selectedReport, value: selectedValue==='Network'? selectedReportType: selectedReport }}
                placeholder={'Select'}
                options={selectedValue=='Network'? networkReportType : reportType}
                onChange={selectedValue=='Network'? setSelectedReportType : setSelectedReport}
                isOptionDisabled={(option) => option.isDisabled}
              /> */}
              {/* <Select1 
				
				defaultValue={
					selectedValue === 'Network'
					  ? { label: selectedReportType, value: selectedReportType }
					  : { label: selectedReport, value: selectedReport }
				  }
					// defaultValue={selectedValue === 'Network' ? selectedReportType : selectedReport === "null" ? "" : { label: selectedValue === 'Network' ? selectedReportType: selectedReport, value: selectedValue === 'Network' ? selectedReportType : selectedReport }} 
					placeholder={'Select'} 
					onSelecet={console.log("@@@@@@",selectedValue === 'Network' ? selectedReportType: selectedReport)}
					options={selectedValue === 'Network' ? networkReportType : reportType} 
					onChange={selectedValue === 'Network'? setSelectedReportType : setSelectedReport} 
					isOptionDisabled={(option) => option.isDisabled} /> */}
              <div>
                <label className="label-class mt-4">Select Report Type</label>
                {selectedValue === "Network" ? (
                  <Select1
                    value={
                      selectedReportType === "null"
                        ? ""
                        : {
                            label: selectedReportType,
                            value: selectedReportType,
                          }
                    }
                    placeholder={"Select"}
                    options={networkReportType}
                    onChange={setSelectedReportType}
                    isOptionDisabled={(option) => option.isDisabled}
                  />
                ) : selectedValue === "Media" ? (
					<Select1
					  value={
						selectedMediaReportType === "null"
						  ? ""
						  : {
							  label: selectedMediaReportType,
							  value: selectedMediaReportType,
							}
					  }
					  placeholder={"Select"}
					  options={mediaReportType}
					  onChange={setSelectedMediaReportType}
					  isOptionDisabled={(option) => option.isDisabled}
					/>
				  ) :
				 (
                  <Select1
                    value={
                      selectedReport === "null"
                        ? ""
                        : { label: selectedReport, value: selectedReport }
                    }
                    placeholder={"Select"}
                    options={reportType}
                    onChange={setSelectedReport}
                    isOptionDisabled={(option) => option.isDisabled}
                  />
                )}
              </div>
              <br />
              <br />
              <Button1
                onClick={() => setSelect(false)}
                text={`Generate ${akey ? "UK" : uskey ? "US" : ""} ${
                  selectedValue === "Network"
                    ? selectedNetworkReport
                    : selectedValue === "Media"
                    ? ''
                    : selectedFloor
                } ${
                  selectedValue === "Network"
                    ? selectedReportType
                    : selectedValue === "Media"
                    ? selectedMediaReportType
                    : selectedReport
                } Health Report`}
                borderRadius={"10px"}
                background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                color={"#ffffff"}
              />
            </div>
          ) : (
            <div>
              {console.log("jdbkjfbsjkdbfs", selectedValue)}
              {selectedValue !== "Network" && selectedValue !== "Media"  ? (
                <Report
                  setroute={setloggedIn}
                  akey={akey}
                  uskey={uskey}
                  type={selectedFloor}
                  report={selectedReport}
                />
              ) : selectedValue === "Media" ? (
                <MediaReport
                  setroute={setloggedIn}
                  akey={akey}
                  uskey={uskey}
                  type={selectedValue}
                  report={selectedMediaReport}
                />
              ) : selectedNetworkReport === "AP" ? (
                <APReport
                  setroute={setloggedIn}
                  akey={akey}
                  uskey={uskey}
                  type={selectedNetworkReport}
                  report={selectedReportType}
                />
              ) :  (
                <TwilioReport
                  setroute={setloggedIn}
                  akey={akey}
                  uskey={uskey}
                  type={selectedNetworkReport}
                  report={selectedReportType}
                />
              )}
            </div>
          )}
        </form>
        <Footer />
      </div>
    </div>
  );
}

export default Health;
