

module.exports = {

	loginURL: 'https://accountws.preprod.afreespace.com/api/login/code?requiredRefreshToken=true',
	loginURLUS: 'https://accountws-bi-01.qa.afreespace.com/api/login/code?requiredRefreshToken=true',

	refreshTokenURL: 'https://accountws.preprod.afreespace.com/api/refresh-token?requiredRefreshToken=true',
	refreshTokenURLUS: 'https://accountws-bi-01.qa.afreespace.com/api/refresh-token?requiredRefreshToken=true',

	ADMINURL: 'https://admin.preprod.afreespace.com/',

	tim: 'https://analytics.preprod.afreespace.com/apiv2/report/tim-health',
	timus: 'https://analytics.us.afreespace.com/apiv2/report/tim-health',

	timp: "https://analytics.preprod.afreespace.com/apiv2/report/tim_p-health",
	timpus: "https://analytics.us.afreespace.com/apiv2/report/tim_p-health",

	flo: "https://analytics.preprod.afreespace.com/apiv2/report/flo-health",
	flous: "https://analytics.us.afreespace.com/apiv2/report/flo-health",

	batTim: "https://analytics.preprod.afreespace.com/apiv2/report/bat_tim-health",
	batTimus: "https://analytics.us.afreespace.com/apiv2/report/bat_tim-health",

	pamc: "https://analytics.preprod.afreespace.com/apiv2/report/pam_c-health",
	pamcus: "https://analytics.us.afreespace.com/apiv2/report/pam_c-health",

	pal: "https://analytics.preprod.afreespace.com/apiv2/report/pal-health",
	palus: "https://analytics.us.afreespace.com/apiv2/report/pal-health",

	batFlo: "https://analytics.preprod.afreespace.com/apiv2/report/bat_flo-health",
	batFlous: "https://analytics.us.afreespace.com/apiv2/report/bat_flo-health",
	
	base: "https://xrsvp8nwi5.execute-api.eu-west-1.amazonaws.com/prod",

	mediaServerUrl: "https://prodws.afreespace.com/api/admin/media-server"
}

//before moving to prod, change all dev urls to prod
